import { useEffect } from 'react'
import Card from './Card'
import "../styles/DisplayCard.css"

function AddCard({peopleList, deletePeople, deletePeopleOnToss, wrap, wraping, tossing}) {

    useEffect(() => {
        wrap(false)
        let cardContainer = document.getElementById('cardContainer')
        cardContainer.scrollTop = cardContainer.scrollHeight
    }, [wrap, wraping]);

    return (
        <div id="cardContainer" className="cardContainer">
            {peopleList.map(({id, peopleName, peopleNickName, peoplePicture, peopleScore, lastYearWinner}) => (
                <Card
                    key={id} //just for react
                    id={id}
                    peopleName = {peopleName}
                    peopleNickName = {peopleNickName}  
                    peoplePicture = {peoplePicture}
                    peopleScore = {peopleScore}
                    peopleIsLastYearWinner = {lastYearWinner}
                    deletePeople = {deletePeople}
                    deletePeopleOnToss = {deletePeopleOnToss}
                    tossing={tossing}
                />               
            ))}
        </div>
    )
}

export default AddCard
