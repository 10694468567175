import Banner from '../components/Banner'
import { Link } from 'react-router-dom'
import '../styles/NotFound.css'

const NotFound = () => {
    return (
        <>
            <Banner />  
            <h1 className='not-found-title'>
                Oups ! Cette page n'existe pas.
            </h1>
            <p className='not-found-title'>
                Veuillez accéder à la page d'accueil de l'application via ce bouton :
            </p>
            <div className='link-container'>
                <Link to='/' className='link'>Retour à l'accueil</Link>
            </div>
        </>
    );
}

export default NotFound;
