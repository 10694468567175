import Card from './Card'
import '../styles/PopupCard.css'

function PopupCard({
    deletePeople,
    peopleChosen,
    togglePopup,
    togglePopupWinner,
    executeToss,
    winner,
    exportList,
    resetListWithScore
}) {

  return (
    <div className="backPopup">
        <div className="popup">
            { winner ?
            <div className="youAreWinner">
                <span>Félicitation {peopleChosen.peopleName}, tu as gagné !</span>
            </div> 
            :
            <div className="yourTurn">
                <span>A ton tour {peopleChosen.peopleName} !</span>
            </div>
            }
            <Card
                id={peopleChosen.id}
                peopleName = {peopleChosen.peopleName}
                peopleNickName = {peopleChosen.peopleNickName}  
                peoplePicture = {peopleChosen.peoplePicture}
                peopleScore = {peopleChosen.peopleScore}
                peopleIsLastYearWinner = {peopleChosen.lastYearWinner}
                deletePeople = {deletePeople}
                chosen= {true}
                winner= {winner}
            />
            { winner ?
            <div className="popupButtonContainer">
                <button 
                    className="popupButton"
                    onClick={ () => {
                        exportList(peopleChosen.peopleName)
                        deletePeople("Card"+peopleChosen.id)
                        resetListWithScore()
                        togglePopupWinner(false)
                    }}
                >
                    Merci !
                </button>
            </div>
            :
            <div className="popupButtonContainer">
                <button 
                    className="popupButton"
                    onClick={ _ => {
                        deletePeople("Card"+peopleChosen.id)
                        executeToss(true)
                        togglePopup(false)
                    }}
                >
                    Continuer
                </button>
                <button 
                    className="popupButton"
                    onClick={ _ => {
                        deletePeople("Card"+peopleChosen.id)
                        togglePopup(false)
                    }}
                >
                    Retirer
                </button>
                <button 
                    className="popupButton"
                    onClick={ _ => togglePopup(false)}
                >
                    Laisser
                </button>
            </div>
            }
        </div>
    </div>
  )
}

export default PopupCard;
