import { Link } from 'react-router-dom';
import Banner from '../components/Banner'
import '../styles/LegalMention.css'

const LegalMention = () => {
    return (
        <>
            <Banner />
            <div className='legal-mention-container'>
                <h1>Mentions légales</h1>
                <div>
                    <h2>Propriétaire</h2>
                    <p>
                        Cette application a été conçue et développée par Mr LENGRAND Florian.
                    </p>
                </div>
                <div>
                    <h2>Hébergeur</h2>
                    <p>
                        L'application est hébergée sur un serveur OVH.
                    </p>
                    <p>Société OVH</p>
                    <p>Siège social : 2 rue Kellermann - 59100 Roubaix, France</p>
                    <p>+33 (0)8 99 70 17 61</p>
                    <p><a href="https://www.ovhcloud.com/fr/">https://www.ovhcloud.com/fr/</a></p>
                </div>
                <div>
                    <h2>Propriété intellectuelle</h2>
                    <p>
                        Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments
                        du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
                        préalable de Mr LENGRAND Florian.
                    </p>
                    <p>
                        Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera
                        considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions
                        des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                    </p>
                </div>
                <div>
                    <h2>Utilisation des données personnelles</h2>
                    <p>
                        Aucune donée n'est enregistrée. <br />
                        Les images sélectionnées sont utilisées uniquement au niveau de l'affichage et de façon temporaire. <br />
                        Elles ne sont pas stockées sur un serveur ni envoyées à des tiers.
                    </p>
                </div>
                <div className='link-container'>
                    <Link to='/' className='link-to-main'>Retour au site</Link>
                </div>
            </div>
        </>
    );
}

export default LegalMention;
