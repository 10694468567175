import { useState } from 'react'
import "../styles/AddCard.css"

function AddCard({peopleList, addPeopleToList, id, incrId, wrap, listWithScore, addToListWithScore}) {

    const [peopleName, updatePeopleName] = useState("?")
    const [peopleNickName, updatePeopleNickName] = useState("")
    const [peoplePicture, updatePeoplePicture] = useState(null)
    const [peoplePictureName, updatePeoplePictureName] = useState(null)

    function addCardToList(event) {
        event.preventDefault()
        addPeopleToList([...peopleList,
            {
                id,
                peopleName,
                peopleNickName,
                peoplePictureName,
                peoplePicture,
                peopleScore : 0
            }
        ])
        addToListWithScore([...listWithScore,
            {
                id,
                peopleName,
                peopleNickName,
                peoplePictureName,
                peoplePicture,
                peopleScore : 0
            }
        ])
        incrId(id+1)
        updatePeopleName("?")
        updatePeopleNickName("")
        document.getElementById("addCardContainer").reset()
        document.getElementById("fileInputContainer").style.backgroundImage = ''
        updatePeoplePicture(null)
        wrap(true)
    }       

    function changePeopleName(event) {
        updatePeopleName(event.target.value)
    }

    function changePeopleNickName(event) {
        updatePeopleNickName(event.target.value)
    }

    function getFile(event) {
        if(event.target.files[0] !== undefined) {
            let urlPicture = URL.createObjectURL(event.target.files[0]);
            document.getElementById("fileInputContainer").style.backgroundImage = "url("+urlPicture+")"
            updatePeoplePicture(urlPicture)
            updatePeoplePictureName(event.target.files[0].name)
        }
    }

    return (
        <form onSubmit={addCardToList} className="addCardContainer" id="addCardContainer">
            <div 
                className="fileInputContainer"
                id="fileInputContainer"
            >
                <label htmlFor="fileInput" className="label-fileInput">Choisissez votre image</label>
                <input 
                    type="file"
                    id="fileInput"
                    className="fileInput"
                    accept=".jpeg,.png,.jpg"
                    onChange={getFile}
                />
            </div>
            <div className="nameInputContainer">
                <input 
                    id="nameInput"
                    autoComplete="off"
                    className="nameInput"
                    type="text"
                    placeholder="Prénom..."
                    onChange={changePeopleName}
                    required
                />
            </div>
            <div className="nickNameInputContainer">
                <input 
                    id="nickNameInput"
                    autoComplete="off"
                    className="nickNameInput"
                    type="text"
                    placeholder="Surnom..."
                    onChange={changePeopleNickName}
                />
            </div>
            <div className="addButtonContainer">
                <input type="submit" value="+" className="addButton" />
            </div>
        </form>
    )
}

export default AddCard
