import { Routes, Route } from 'react-router-dom'
import Main from '../views/Main'
import NotFound from '../views/NotFound'
import LegalMention from '../views/LegalMention'

function App() {
    return (
        <Routes>
            <Route path='/' element={<Main />} />
            <Route path='/mentions-legales' element={<LegalMention />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
}

export default App;
