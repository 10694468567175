import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import "../styles/Banner.css"

function Banner({
    peopleList, 
    addPeopleToList, 
    id, 
    incrId, 
    exportList, 
    togglePopupInfo, 
    listWithScore,
    addToListWithScore,
    fastMode,
    setFastMode,
}) {

    const [files, updateFiles] = useState(null)
    const [tempData, updateTempData] = useState(null)

    const location = useLocation()

    // ##### IMPORT ##### //

    useEffect(() => {
        if (files !== null)
            getListOfPeople()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);    

    useEffect(() => {
        let findedPicture = false
        if (tempData !== null) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].type !== "application/json") {
                    if (
                        tempData.pictureName === files[i].name
                        && tempData.name !== undefined
                        && tempData.name !== ""
                        && tempData.name !== null
                    ) {
                        let urlJsonFile = URL.createObjectURL(files[i]);
                        let score = 0
                        if (tempData.score !== undefined) {
                            score = tempData.score
                        }
                        let lastYearWinner = false
                        if (tempData.lastYearWinner !== undefined) {
                            lastYearWinner = tempData.lastYearWinner
                        }
                        let newPeople = {
                            id : id,
                            peopleName : tempData.name,
                            peopleNickName : tempData.nickName,
                            peoplePictureName : tempData.pictureName,
                            peoplePicture : urlJsonFile,
                            peopleScore : score,
                            lastYearWinner: lastYearWinner
                        }
                        addPeopleToList([...peopleList, newPeople])
                        addToListWithScore([...listWithScore, newPeople])
                        incrId(id+1)
                        findedPicture = true
                    }
                }
            }
            if (
                !findedPicture
                && tempData.name !== undefined
                && tempData.name !== ""
                && tempData.name !== null
            ) {
                let score = 0
                if (tempData.score !== undefined) {
                    score = tempData.score
                }
                let lastYearWinner = false
                if (tempData.lastYearWinner !== undefined) {
                    lastYearWinner = tempData.lastYearWinner
                }
                let newPeople = {
                    id : id,
                    peopleName : tempData.name,
                    peopleNickName : tempData.nickName,
                    peoplePictureName : tempData.pictureName,
                    peoplePicture : null,
                    peopleScore : score,
                    lastYearWinner: lastYearWinner
                }
                addPeopleToList([...peopleList, newPeople])
                addToListWithScore([...listWithScore, newPeople])
                incrId(id+1)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempData]);

    function loadFiles(event) {
        updateFiles(event.target.files)
    } 

    function getListOfPeople() {
        if( files !== null) {
            for (let i = 0; i < files.length; i++) {
                if ( files[i].type === "application/json" ) {
                    let urlJsonFile = URL.createObjectURL(files[i]);
                    // on récupère les données avec fetch sur l'url générée
                    fetch(urlJsonFile,{
                        headers : {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }).then(function(response){
                        // on retourne la réponse au format JSON
                        return response.json();
                    }).then( importedListOfPeople => {
                        // et on traite chaque donnée
                        let datas = []
                        for (const data of importedListOfPeople) {
                            datas.push(data)
                        }
                        datas.sort( (a,b) => b.score-a.score)
                        for (const people of datas) {
                            updateTempData(people)
                        }
                    })
                    URL.revokeObjectURL(urlJsonFile)
                }
            }
        }
    }

    return (
        <header className="banner">
            <div className="bannerTitle">
                <a href="./">It's daily time !</a>
            </div>

            {location.pathname === '/' && <>
                <div className='fastModeContainer'>
                    Mode {fastMode ? 'Rapide' : 'Simple'}
                    <label htmlFor='fastModeButton' className='switch'>
                        <input id='fastModeButton' type="checkbox" onChange={_ => setFastMode(!fastMode)} />
                        <span></span>
                    </label>
                </div>

                <button className="exportButton" onClick={exportList}>Exporter</button>

                <label htmlFor="importList" className="importListLabel">Importer</label>
                <input 
                    type="file" 
                    id="importList" 
                    className="importListButton" 
                    accept=".json,.jpeg,.png,.jpg" 
                    multiple
                    onClick={() => document.getElementById("importList").value = ""} 
                    onChange={loadFiles}/>

                <button className="infoButton" onClick={_ => togglePopupInfo(true)}>?</button>
            </>}
        </header>
    )
}

export default Banner
