import { useState } from 'react'
import "../styles/DisplayCard.css"
import defaultPicture from "../assets/defaultPicture.svg"

function Card({
    id,
    peopleName,
    peopleNickName,
    peoplePicture,
    peopleScore,
    peopleIsLastYearWinner,
    deletePeople,
    deletePeopleOnToss,
    chosen,
    winner,
    tossing
}) {

    const [deleteButtonActive, setDeleteButtonToActive] = useState(false)

    let className = "card"
    if (winner) {
        className += " winner"
    } else if (chosen) {
        className += " chosen"
    }
    console.log(peopleIsLastYearWinner)
    if (peopleIsLastYearWinner) {
        className += " last-year-winner"
    }

    return (
        <div className={ className } id={"Card"+id} >
            { !chosen && !tossing &&
            <div className="cardDeleteContainer" >
                {!deleteButtonActive ?
                <button
                    onClick={ _ => {
                        setDeleteButtonToActive(true)
                    }}
                    className="cardShowButtons"
                >
                    ◄
                </button>
                : 
                <>
                    <button 
                        onClick={ _ => {
                            setDeleteButtonToActive(false)
                        }} 
                        className="cardShowButtons"
                    >
                        ▼
                    </button>
                    <button 
                        onClick={ _ => {
                            setDeleteButtonToActive(false)
                            deletePeopleOnToss("Card"+id)
                        }} 
                        className="cardMaskButton" 
                        title="masquer"
                    >
                    </button>
                    <button 
                        onClick={ _ => {
                            setDeleteButtonToActive(false)
                            deletePeople("Card"+id)
                        }} 
                        className="cardDeleteButton" 
                        title="supprimer"
                    >
                    </button>
                </>
                }
            </div>
            }
            <div className="pictureContainer">
                <img 
                    src={peoplePicture !== null ? peoplePicture : defaultPicture} 
                    alt={"Picture of " + peopleName + " alias " + peopleNickName}
                />
            </div>
            <div className="nameContainer">
                <span>{peopleName}</span>

                <span className="nickName">{ peopleNickName ?? ''}</span>

                <span className="score">
                    {winner ? <>Nouveau score : {peopleScore+1}</> : <>Score : {peopleScore}</>}
                </span>
            </div>
        </div>
    )
}

export default Card