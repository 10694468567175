import { useState } from 'react'
import '../styles/PopupCard.css'
import '../styles/PopupInfo.css'

function PopupInfo({togglePopupInfo}) {

    const [showImport, setShowImport] = useState(false)
    const [showExport, setShowExport] = useState(false)
    const [showJsonInstruction, setShowJsonInstruction] = useState(false)
    const [showScoreInstruction, setShowScoreInstruction] = useState(false)

    let acO = "{"
    let acF = "}"

    return (
        <div className="backPopup">
            <div className="popupInfo">
                <div className="infoPopup">
                    <h1>Informations</h1>
                    <p>
                        Ajoutez des personnes pour le tirage via le template sur la droite (formats 
                        d'image acceptés : .jpeg .png .jpg).<br/>
                        <strong>Conseil : </strong>utilisez des images de même dimension pour un meilleur rendu.<br/>
                        Vous pouvez également <strong>importer</strong> ou <strong>exporter</strong> une liste 
                        via les boutons situés en haut à droite 
                        (<strong>non disponible sur mobile et tablette</strong>).<br/><br/>
                    </p>

                    <div className="displayButtonsContainer">
                        <button className="displayButtons" onClick={_ => {
                            setShowExport(false)
                            setShowJsonInstruction(false)
                            setShowScoreInstruction(false)
                            setShowImport(!showImport)
                        }}>
                            Importation
                        </button>
                        <button className="displayButtons" onClick={_ => {
                            setShowImport(false)
                            setShowJsonInstruction(false)
                            setShowScoreInstruction(false)
                            setShowExport(!showExport)
                        }}>
                            Exportation
                        </button>
                        <button className="displayButtons" onClick={_ => {
                            setShowImport(false)
                            setShowExport(false)
                            setShowScoreInstruction(false)
                            setShowJsonInstruction(!showJsonInstruction)
                        }}>
                            Fichier JSON
                        </button>
                        <button className="displayButtons" onClick={_ => {
                            setShowImport(false)
                            setShowExport(false)
                            setShowJsonInstruction(false)
                            setShowScoreInstruction(!showScoreInstruction)
                        }}>
                            Score
                        </button>
                    </div>

                    <div className="instructionsContainer">
                        { showImport ?
                        <p>
                            <strong>Importation : </strong>séléctionnez un fichier au format json 
                            (voir section "Fichier JSON") ainsi que les photos des personnes à tirer au sort,
                            puis cliquez sur "Ouvrir".<br/>
                            Si vous avez déjà exporté une liste, il vous suffit de séléctionner le fichier téléchargé
                            ainsi que les photos que vous avez utilisé pour faire cette liste.<br/>
                            <strong>Tous les fichiers doivent être dans le même dossier.</strong>
                        </p> 
                        :
                        showExport ?
                        <p>
                            <strong>Exportation : </strong> cliquer sur "exporter" lance le téléchargement du fichier
                            json correspondant à votre liste en cours,<br/>
                            <strong>y compris les personnes masquées.</strong><br/>
                            Les images ne sont pas exportées, seulement le json.<br/>
                            Ensuite placez ce fichier dans le même dossier que les photos utilisées dans la liste.<br/>
                            Vous pourrez importer votre liste directement la prochaine fois.<br/><br/>
                        </p> 
                        :
                        showJsonInstruction ?
                        <>
                            <p><strong>Fichier json : </strong>votre fichier doit être formé comme suit :</p>       
                            <ul>
                                <li>[{acO}</li>
                                <ul>
                                    <li>"name" : "prenom1",</li>
                                    <li>"nickName" : "surnom1",</li>
                                    <li>"pictureName" : "photo1.jpg"</li>
                                </ul>
                                <li>{acF},</li>
                                <li>{acO}</li>
                                <ul>
                                    <li>"name" : "prenom2",</li>
                                    <li>"pictureName" : "photo2.jpg"</li>
                                </ul>
                                <li>{acF}]</li>
                            </ul>
                        </> 
                        :
                        showScoreInstruction &&
                        <>
                            <p>
                                <strong>Système de score : </strong>le score augmente lorsque vous êtes le dernier a être
                                séléctionné.<br/>
                                A la fin du tirage au sort, un fichier "its_daily_time_scored.json" est téléchargé
                                automatiquement.<br/>
                                Celui-ci contient votre liste avec les nouveaux scores.<br/>
                                <strong>Vous devez donc remplacer votre fichier json par celui-ci après chaque 
                                tirage si vous souhaitez garder vos scores.</strong>
                                <br/><br/>
                                <strong>Supprimer/Masquer : </strong>lorsque vous cliquez sur le bouton ◄ présent en 
                                haut à droite des cartes, vous avez deux choix :
                            </p>
                            <ul>
                                <li>
                                    - <strong>Masquer (ø) : </strong>cliquez ici si la personne est absente mais que 
                                    vous souhaitez garder son score.<br/>
                                </li>
                                <br/>
                                <li>
                                    - <strong>Supprimer (x) : </strong>cliquez ici si vous souhaitez supprimer une personne
                                    de la liste.<br/>
                                    <strong>Attention : le score de cette personne sera perdu.</strong>
                                </li>
                            </ul>
                        </> 
                        }
                    </div>
                    <p>
                        Seul le nom est obligatoire, les photos seront remplacées par une image par défaut et le surnom
                        ne sera pas affiché.
                    </p>
                    <strong>Aucune donnée n'est enregistrée</strong>
                </div>
                <div className="popupInfoButtonContainer">
                    <button 
                        className="popupButton"
                        onClick={_ => togglePopupInfo(false)}
                    >
                        Compris !
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PopupInfo;
