import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AddCard from '../components/AddCard'
import Banner from '../components/Banner'
import DisplayCard from '../components/DisplayCard'
import PopupCard from '../components/PopupCard'
import PopupInfo from '../components/PopupInfo'
import '../styles/Main.css'

function Main() {

    const [peopleList, addPeopleToList] = useState([])
    const [listWithScore, addToListWithScore] = useState([])

    const [id, incrId] = useState(1)

    const [buttonText, changeButtonText] = useState("Tirer au sort")

    const [peopleChosen, setPeopleChosen] = useState(null)

    const [showPopup, togglePopup] = useState(false)
    const [showPopupWinner, togglePopupWinner] = useState(false)
    const [showPopupInfo, togglePopupInfo] = useState(false)

    const [tossFunction, executeToss] = useState(false)
    const [tossing, toggleToss] = useState(false)

    const [wraping, wrap] = useState(false)

    const [fastMode, setFastMode] = useState(false)

    useEffect(() => {
        if (peopleList.length >= 1) {
            document.getElementById("toss").disabled = false
            document.getElementById("toss").classList.remove("emptyList")
        } else {
            document.getElementById("toss").disabled = true
            document.getElementById("toss").classList.add("emptyList")
        }
    }, [peopleList])

    useEffect(() => {
        if (tossFunction) {
            toss()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tossFunction])

    function toss() {
        if (peopleList.length === 1) {
            setPeopleChosen(peopleList[0])
            window.scrollTo(0,0)
            togglePopupWinner(true)
        } else if (peopleList.length > 1) {
            if (!fastMode) {
                document.getElementById("cardContainer").style.width = "100%"
                document.getElementById("addCardContainer").style.display = "none"
            }
            window.scrollTo(0,0)
            executeToss(false)
            toggleToss(true)
            changeButtonText("Suspens...")
            document.getElementById("toss").disabled = true
            function getRandomInt(max) {
                return Math.floor(Math.random() * max)
            }
            let tabOfIndexAlea = []
            let numberOfIndexAlea = fastMode ? 1 : 4
            for (let i = 0; i < numberOfIndexAlea; i++) {
                let indexAlea = getRandomInt(peopleList.length)
                tabOfIndexAlea.push(indexAlea)
            }
            let cpt = 0
            let divToActive = fastMode ? 'undefined' : null
            for (let i = 0; i < numberOfIndexAlea-1 ; i++) {
                divToActive = document.getElementById("Card"+peopleList[tabOfIndexAlea[i]].id)
                let d = divToActive
                setTimeout(() => {
                    d.classList.add("active")
                }, cpt)
                setTimeout(() => {
                    d.classList.remove("active")
                }, cpt + 1000)
                cpt += 1100
            }
            setPeopleChosen(peopleList[tabOfIndexAlea[numberOfIndexAlea-1]])
            setTimeout(() => {
                // toss finish
                if (divToActive !== null) {
                    // open popup with card chosen
                    togglePopup(true)  
                    changeButtonText("Tirer au sort")
                    window.scrollTo(0,0)
                }
                toggleToss(false)
                if (window.innerWidth <= 650)
                    document.getElementById("cardContainer").style.width = "90%"
                else
                    document.getElementById("cardContainer").style.width = "60%"
                document.getElementById("addCardContainer").style.display = "flex"
                document.getElementById("toss").disabled = false
            }, cpt)
        }
    }

    function exportList(winner) {
        if (listWithScore.length > 0 && window.innerWidth > 650) {
            let jsonContent = []
            listWithScore.forEach(people => {
                if (winner === people.peopleName) {
                    jsonContent.push({
                        "name" : people.peopleName,
                        "nickName" : people.peopleNickName,
                        "pictureName" : people.peoplePictureName,
                        "score" : people.peopleScore+1,
                        "lastYearWinner": people.lastYearWinner
                    })
                } else {
                    jsonContent.push({
                        "name" : people.peopleName,
                        "nickName" : people.peopleNickName,
                        "pictureName" : people.peoplePictureName,
                        "score" : people.peopleScore,
                        "lastYearWinner": people.lastYearWinner
                    })
                }
            })
            var jsonFile = new File([JSON.stringify(jsonContent)], "list.json", {
                type: "application/json",
            });
            let urlJsonFile = URL.createObjectURL(jsonFile)
            var a = document.createElement("a")
            a.href = urlJsonFile
            a.setAttribute("download", "its_daily_time_scored.json")
            a.click();
        }
    }

    function resetListWithScore() {
        addToListWithScore([])
    }

    function deletePeople (cardID) {
        // libère l'url crée pour l'image
        const urlPicture = document.getElementById(cardID).childNodes[1].childNodes[0].src
        URL.revokeObjectURL(urlPicture)
        // ------------------------------
        const newList = peopleList.filter( data => cardID !== "Card"+data.id )
        addPeopleToList(newList)
        const newListWithScore = listWithScore.filter( data => cardID !== "Card"+data.id )
        addToListWithScore(newListWithScore)
    }

    function deletePeopleOnToss (cardID) {
        const urlPicture = document.getElementById(cardID).childNodes[1].childNodes[0].src
        URL.revokeObjectURL(urlPicture)
        const newList = peopleList.filter( data => cardID !== "Card"+data.id )
        addPeopleToList(newList)
    }

  return (
    <>
        <Banner 
            peopleList={peopleList} 
            addPeopleToList={addPeopleToList} 
            id={id} 
            incrId={incrId} 
            exportList={exportList}
            togglePopupInfo={togglePopupInfo} 
            listWithScore={listWithScore} 
            addToListWithScore={addToListWithScore}
            fastMode={fastMode}
            setFastMode={setFastMode}
        />
        <div className="mainContainer">
            <DisplayCard 
                peopleList={peopleList} 
                deletePeople={deletePeople} 
                wraping={wraping} 
                wrap={wrap} 
                tossing={tossing}
                deletePeopleOnToss={deletePeopleOnToss}
            />
            <AddCard 
                peopleList={peopleList} 
                addPeopleToList={addPeopleToList} 
                id={id} 
                incrId={incrId} 
                wrap={wrap}
                listWithScore={listWithScore}
                addToListWithScore={addToListWithScore}
            />
        </div>
        <button id="toss" className="toss" onClick={toss}>
            {buttonText}
        </button>
        <Link to='/mentions-legales' className='link-to-legal-mention'>Mentions légales</Link>
        { showPopup &&
            <PopupCard 
                deletePeople={deletePeopleOnToss} 
                peopleChosen={peopleChosen} 
                togglePopup={togglePopup} 
                executeToss={executeToss} 
            /> 
        }
        { showPopupWinner && 
            <PopupCard 
                deletePeople={deletePeopleOnToss}
                peopleChosen={peopleChosen}
                winner={true}
                togglePopupWinner={togglePopupWinner}
                exportList={exportList}
                resetListWithScore={resetListWithScore}
            />
        }
        { showPopupInfo && <PopupInfo togglePopupInfo={togglePopupInfo} /> }
    </>
  )
}

export default Main;
